import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

import {UserRes} from '@app/core/resource/user.resource';
import {UserDto} from '@app/core/resource-dto/user';

import {BsTableControl} from '@app/shared/directives/bs-table-control.directive';
import {AuthService} from '@app/core/services/auth.service';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ConfigService} from '@app/config/config.service';
import {HttpParams} from '@angular/common/http';
import {FilterListComponent} from '@app/user-registry/filter-list.component';
import * as moment from 'moment';
import {ClassifierService} from '@app/core/services/classifier.service';
import {ClassifierDto} from '@app/core/resource-dto/classifier';

declare let jQuery: any;

@Component({
    templateUrl: './list.component.html',
    providers: [DatePipe]
})
export class UserListComponent extends FilterListComponent implements AfterViewInit, OnDestroy, OnInit {
    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(BsTableControl)
    bsTableControl: BsTableControl;

    @ViewChild('periodDatetimepicker', {static: true})
    periodDatetimepickerRef: ElementRef;

    @ViewChild('bsTableElement')
    bsTableElement: ElementRef;

    @ViewChild('companyDropdown', {static: true})
    companyDropdownRef: ElementRef;

    @ViewChild('representationRightDropdown', {static: true})
    representationRightDropdownRef: ElementRef;

    @ViewChild('personalCodeDropdown', {static: true})
    personalCodeDropdownRef: ElementRef;

    @ViewChild('countryDropdown', {static: true})
    countryDropdownRef: ElementRef;

    @ViewChild('nameDropdown', {static: true})
    nameDropdownRef: ElementRef;

    @ViewChild('sourceDropdown', {static: true})
    sourceDropdownRef: ElementRef;

    @ViewChild('daterangeDropdown', {static: true})
    daterangeDropdownRef: ElementRef;

    @ViewChild('filterDateFrom', {static: true})
    filterDateFromRef: ElementRef;

    @ViewChild('filterDateTo', {static: true})
    filterDateToRef: ElementRef;

    @ViewChild('largeClientManagerDropdown', {static: true})
    largeClientManagerDropdownRef: ElementRef;

    @ViewChild('ministryDropdown', {static: true})
    ministryDropdownRef: ElementRef;

    filterFormGroup: FormGroup;
    public permissions$: Observable<any>;
    public currentPermissions: any = {};
    public users: UserDto.User[] = null;
    public sources: any[] = [{id: 0, text: 'Sisene'}, {id: 1, text: 'Väline'}];
    public sourceOptions: any[];
    public largeClientManagerOptions: any[] = [];
    public userCompanyId: number;
    public ministryTypes = [];
    private largeClientManagerUsers: UserDto.User[];
    private query = {};

    public bsTableOptions: any = {
        toolbar: '#toolbar',
        iconsPrefix: 'font-icon',
        icons: {
            paginationSwitchDown: 'font-icon-arrow-square-down',
            paginationSwitchUp: 'font-icon-arrow-square-down up',
            refresh: 'font-icon-refresh',
            toggle: 'font-icon-list-square',
            columns: 'font-icon-list-rotate',
            export: 'font-icon-download',
            detailOpen: 'font-icon-plus',
            detailClose: 'font-icon-minus-1'
        },
        paginationPreText: '<i class="font-icon font-icon-arrow-left"></i>',
        paginationNextText: '<i class="font-icon font-icon-arrow-right"></i>',
        sidePagination: 'server',
        pagination: true,
        pageList: [10, 25, 50, 100],
        pageSize: 10,
        formatAllRows: function () {
            return 'Kõik';
        },
        showColumns: true,
        columns: [
            {
                formatter: (value: any, row: any, rowIndex: any) => {
                    if (row.countryCode) {
                        return '<a href="#" class="user-link" data-id="' + row.id + '">' + row.countryCode + ' ' + row.personalCode + '</a>';
                    } else {
                        return '<a href="#" class="user-link" data-id="' + row.id + '">' + row.personalCode + '</a>';
                    }
                }
            },
            {
                searchFormatter: true,
                formatter: (value: any, row: any, rowIndex: any) => {
                    return row.countryName;
                }
            },
            {},
            {
                searchFormatter: true,
                formatter: (value: any, row: any, rowIndex: any) => {
                    return value ? 'Väline' : 'Sisene';
                }
            },
            {
                formatter: (value: any, row: any, rowIndex: any) => {
                    return this.datePipe.transform(row.registrationTimestamp, 'dd.MM.yyyy HH:mm');
                },
                sorter: function (a: any, b: any) {
                    if (a > b) return 1;
                    if (a < b) return -1;
                    return 0;
                },
            }
        ],
        data: [],
        ajax: (params: any) => this.ajaxCallBack(params),
        cookie: true,
        filterShowClear: true,
        cookieIdTable: 'user-list',
        cookieStorage: 'sessionStorage'
    };

    public quickFilter = 'active';

    public filters: any = {
        company: null,
        representationRight: null,
        personalCode: null,
        country: null,
        name: null,
        source: null,
        dateFrom: null,
        dateTo: null,
        largeClientManager: [],
        ministry: null,
        search: null
    };

    constructor(
        private router: Router,
        private datePipe: DatePipe,
        private userRes: UserRes,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private formBuilder: FormBuilder,
        private config: ConfigService,
        private classifierService: ClassifierService
    ) {
        super();

        this.sourceOptions = this.sources.map(item => {
            return {
                id: item.id,
                text: item.text
            }
        });

        this.authService.user$.subscribe((_) => {
            this.userCompanyId = this.authService.getCurrentUserCompany().id;
        });

        this.permissions$ = this.permissionsService.permissions$;

        this.permissions$.pipe(distinctUntilChanged()).subscribe((permissions: any) => {
            this.currentPermissions = permissions;
        });

        this.classifierService.classifierSource$.pipe(takeUntil(this.ngDestroy)).subscribe((_) => {
            const ministryTypeClassifier: ClassifierDto.Classifier = this.classifierService.getClassifierByCode('MINTISTRY_TYPE');
            this.ministryTypes = ministryTypeClassifier.values.map(item => {
                return {
                    id: item.code,
                    name: item.name
                }
            });
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.setSavedFiltersToFilters();

        this.filterFormGroup = this.formBuilder.group({
            'company': this._filters.company !== null ? this._filters.company : null,
            'representationRight': this._filters.representationRight !== null ? this._filters.representationRight : null,
            'personalCode': this._filters.personalCode !== null ? this._filters.personalCode : null,
            'country': this._filters.country !== null ? this._filters.country : null,
            'name': this._filters.name !== null ? this._filters.name : null,
            'source': this._filters.source !== null ? this._filters.source : null,
            'dateFrom': this._filters.dateFrom !== null ? this._filters.dateFrom : null,
            'dateTo': this._filters.dateTo !== null ? this._filters.dateTo : null,
            'largeClientManager': this._filters.largeClientManager !== null ? this._filters.largeClientManager : [],
            'ministry': this._filters.ministry !== null ? this._filters.ministry : null,
            'search': this._filters.search !== null ? this._filters.search : null
        });

        this.setSearchFilter();
    }

    ngAfterViewInit() {
        let $table = jQuery(this.bsTableElement.nativeElement);

        if ($table.length) {
            $table.on('click', '.user-link', (e: any) => {
                e.preventDefault();

                let id: any = jQuery(e.target).data('id');
                if (id) {
                    this.router.navigate(['/user', id]);
                }

                return false;
            });
        }

        this.initFilters();
        this.getLargeClientManagers()
        this.quickFilter = 'active';

        if ((this._filters.dateFrom !== null && this._filters.dateFrom !== undefined)
            && (this._filters.dateTo !== null && this._filters.dateTo !== undefined)) {

            this.filterFormGroup.get('dateFrom').setValue(moment(this._filters.dateFrom).format('YYYY-MM-DD\xa0HH:mm'))
            this.filterFormGroup.get('dateTo').setValue(moment(this._filters.dateTo).format('YYYY-MM-DD\xa0HH:mm'))
        }
    }

    public setSavedFiltersToFilters() {
        if (Object.keys(this._filters).length > 0) {
            this.filters = {
                company: this._filters.company !== null ? this._filters.company : null,
                representationRight: this._filters.representationRight !== null ? this._filters.representationRight : null,
                personalCode: this._filters.personalCode !== null ? this._filters.personalCode : null,
                country: this._filters.country !== null ? this._filters.country : null,
                name: this._filters.name !== null ? this._filters.name : null,
                source: this._filters.source !== null ? this._filters.source : null,
                dateFrom: this._filters.dateFrom !== null ? this._filters.dateFrom : null,
                dateTo: this._filters.dateTo !== null ? this._filters.dateTo : null,
                largeClientManager: this._filters.largeClientManager !== null ? this._filters.largeClientManager : [],
                ministry: this._filters.ministry !== null ? this._filters.ministry : null,
                search: this._filters.search !== null ? this._filters.search : null
            }
        }
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    ajaxCallBack(params: any) {
        this.authService.userCompany$.pipe(takeUntil(this.ngDestroy)).subscribe((userCompany: UserLoginDto.UserCompany) => {
            let query = this.createQuery();
            query.limit = params.data.limit;
            query.offset = Math.floor(params.data.offset / params.data.limit);

            if (params.data.sort) {
                query.sortFields = params.data.order == 'desc' ? encodeURIComponent('-' + params.data.sort) : '+' + params.data.sort;
            }

            this.userRes.query(query).then(
                (queryOutput: UserDto.QueryOutput) => {
                    this.users = queryOutput.users;
                    let rows: Array<Object> = this.users.map(dto => {
                        return {
                            companyCount: dto.companyCount,
                            email: dto.email,
                            forename: dto.forename,
                            gdprAccepted: dto.gdprAccepted,
                            id: dto.id,
                            info: dto.info,
                            isExternal: dto.isExternal,
                            personalCode: dto.personalCode ? dto.personalCode : '',
                            phone: dto.phone,
                            registrationTimestamp: dto.registrationTimestamp,
                            sapCostCenter: dto.sapCostCenter,
                            surname: dto.surname,
                            verifiedTimestamp: dto.verifiedTimestamp,
                            name: dto.forename + ' ' + dto.surname,
                            country: dto.country,
                            countryCode: dto.country ? dto.country.code : '',
                            countryName: dto.country ? dto.country.name : '',
                        };
                    });
                    this.bsTableControl.load(rows);

                    params.success({
                        total: queryOutput.size,
                        rows: rows
                    });
                },
                error => {
                    console.log('error', <any>error);
                    params.success({
                        total: 0,
                        rows: []
                    });
                }
            );
        });
    }

    public getLargeClientManagers() {
        let queryInput: UserDto.QueryInput = new UserDto.QueryInput();
        queryInput.companyId = this.userCompanyId;
        queryInput.roleCodes = ['rkas-large-client-manager'];
        queryInput.limit = 50;

        this.userRes.query(queryInput).then(
            (queryOutput: UserDto.QueryOutput) => {
                this.largeClientManagerUsers = queryOutput.users;

                let rows: Array<Object> = this.largeClientManagerUsers.map(dto => {
                    return {
                        id: dto.id,
                        info: dto.info,
                        name: dto.forename + ' ' + dto.surname,
                    };
                });

                rows.forEach(r => {
                    this.largeClientManagerOptions.push(r);
                })

            },
            (error: any) => {
                if (error.status == 404) {
                    this.largeClientManagerOptions = [];
                    return;
                }
                throw error;
            });
    }

    protected getFilterKeys() {
        return {
            source: 'filters.source',
            name: 'filters.name',
            country: 'filters.country',
            personalCode: 'filters.personalCode',
            representationRight: 'filters.representationRight',
            company: 'filters.company',
            largeClientManager: 'filters.largeClientManager',
            dateFrom: 'filters.dateFrom',
            dateTo: 'filters.dateTo',
            ministry: 'filters.ministry',
            search: 'filters.search'
        };
    }

    initFilters() {
        jQuery(this.companyDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.representationRightDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.personalCodeDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.countryDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.nameDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.sourceDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.daterangeDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.largeClientManagerDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });
        jQuery(this.ministryDropdownRef.nativeElement).on('click.bs.dropdown', (e: any) => {
            return false;
        });

        jQuery(this.filterDateFromRef.nativeElement).datetimepicker({
            allowInputToggle: true,
            widgetPositioning: {
                horizontal: 'right'
            },
            debug: false,
            locale: 'et'
        });
        jQuery(this.filterDateFromRef.nativeElement).on('dp.change', (e: any) => {
            this.filterFormGroup.get('dateFrom').setValue(e.date);
        });

        jQuery(this.filterDateToRef.nativeElement).datetimepicker({
            allowInputToggle: true,
            widgetPositioning: {
                horizontal: 'right'
            },
            debug: false,
            locale: 'et'
        });
        jQuery(this.filterDateToRef.nativeElement).on('dp.change', (e: any) => {
            this.filterFormGroup.get('dateTo').setValue(e.date);
        });
    }

    export() {
        let queryParams = new HttpParams();

        for (const key of Object.keys(this.query)) {
            queryParams = queryParams.append(key, this.query[key]);
        }

        let url = this.config.getBackendUrl('/users/xslx');
        url += '?' + queryParams.toString();
        return url
    }

    applyFilters() {
        this.bsTableControl.refresh();
    }

    btnQuickFilterActiveClicked() {
        this.quickFilter = 'active';
        this.applyFilters();
    }

    btnQuickFilterInactiveClicked() {
        this.quickFilter = 'inactive';
        this.applyFilters();
    }

    btnQuickFilterAllClicked() {
        this.quickFilter = 'all';
        this.applyFilters();
    }

    clearCompanyFilter() {
        this.filters.company = null;
        this.filterFormGroup.get('company').setValue(null);
        this.setFilter('company', this.filters.company);
        this.applyFilters();
    }

    applyCompanyFilter() {
        this.filters.company = this.filterFormGroup.getRawValue().company;
        this.setFilter('company', this.filters.company);
        this.applyFilters();
    }

    clearRepresentationRightFilter() {
        this.filters.representationRight = null;
        this.filterFormGroup.get('representationRight').setValue(null);
        this.setFilter('representationRight', this.filters.representationRight);
        this.applyFilters();
    }

    applyRepresentationRightFilter() {
        this.filters.representationRight = this.filterFormGroup.getRawValue().representationRight;
        this.setFilter('representationRight', this.filters.representationRight);
        this.applyFilters();
    }

    clearPersonalCodeFilter() {
        this.filters.personalCode = null;
        this.filterFormGroup.get('personalCode').setValue(null);
        this.setFilter('personalCode', this.filters.personalCode);
        this.applyFilters();
    }

    applyPersonalCodeFilter() {
        this.filters.personalCode = this.filterFormGroup.getRawValue().personalCode;
        this.setFilter('personalCode', this.filters.personalCode);
        this.applyFilters();
    }

    clearCountryFilter() {
        this.filters.country = null;
        this.filterFormGroup.get('country').setValue(null);
        this.setFilter('country', this.filters.country);
        this.applyFilters();
    }

    applyCountryFilter() {
        this.filters.country = this.filterFormGroup.getRawValue().country;
        this.setFilter('country', this.filters.country);
        this.applyFilters();
    }

    clearNameFilter() {
        this.filters.name = null;
        this.filterFormGroup.get('name').setValue(null);
        this.setFilter('name', this.filters.name);
        this.applyFilters();
    }

    applyNameFilter() {
        this.filters.name = this.filterFormGroup.getRawValue().name;
        this.setFilter('name', this.filters.name);
        this.applyFilters();
    }

    clearSourceFilter() {
        this.filters.source = null;
        this.filterFormGroup.get('source').setValue(null);
        this.setFilter('source', this.filters.source);
        this.applyFilters();
    }

    applySourceFilter() {
        this.filters.source = this.filterFormGroup.getRawValue().source;
        this.setFilter('source', this.filters.source)
        this.applyFilters();
    }

    clearDateFilter() {
        this.filters.dateFrom = null;
        this.filterFormGroup.get('dateFrom').setValue(null);
        this.filters.dateTo = null;
        this.filterFormGroup.get('dateTo').setValue(null);
        this.setFilter('dateFrom', this.filters.dateFrom);
        this.setFilter('dateTo', this.filters.dateTo);

        this.applyFilters();
    }

    applyDateFilter() {
        const datePickerDateFrom = $('#datetimePickerFrom').data('datetimepicker').date;
        const datePickerDateTo = $('#datetimePickerTo').data('datetimepicker').date;

        this.filterFormGroup.get('dateFrom').setValue(moment(datePickerDateFrom).format('YYYY-MM-DD\xa0HH:mm'))
        this.filterFormGroup.get('dateTo').setValue(moment(datePickerDateTo).format('YYYY-MM-DD\xa0HH:mm'))

        const filterDateFrom = new Date(moment(datePickerDateFrom).format('YYYY-MM-DD hh:mm:ss A Z'));
        const filterDateTo = new Date(moment(datePickerDateTo).format('YYYY-MM-DD hh:mm:ss A Z'));

        this.filters.dateFrom = filterDateFrom.toISOString();
        this.filters.dateTo = filterDateTo.toISOString();
        this.setFilter('dateFrom', this.filters.dateFrom);
        this.setFilter('dateTo', this.filters.dateTo);
        this.applyFilters();
    }

    applyLargeClientManagerFilter() {
        this.filters.largeClientManager = this.filterFormGroup.getRawValue().largeClientManager;
        this.setFilter('largeClientManager', this.filters.largeClientManager);
        this.applyFilters();
    }

    clearLargeClientManagerFilter() {
        this.filters.largeClientManager = [];
        this.filterFormGroup.get('largeClientManager').setValue([]);
        this.setFilter('largeClientManager', this.filters.largeClientManager);
        this.applyFilters();
    }

    applyMinistryFilter() {
        this.filters.ministry = this.filterFormGroup.getRawValue().ministry;
        this.setFilter('ministry', this.filters.ministry);
        this.applyFilters();
    }

    clearMinistryFilter() {
        this.filters.ministry = null;
        this.filterFormGroup.get('ministry').setValue(null);
        this.setFilter('ministry', this.filters.ministry);
        this.applyFilters();
    }

    public setSearchFilter() {
        this.filterFormGroup.get('search').valueChanges
            .pipe(debounceTime(250)).subscribe(val => {
            if (val === '') {
                this.filters.search = null;
            } else {
                this.filters.search = val;
            }

            this.setFilter('search', this.filters.search);
            this.applyFilters();
        })
    }

    private createQuery() {
        let query: UserDto.QueryInput = new UserDto.QueryInput;

        if (this.filters.search) {
            query.search = this.filters.search;
        }
        query.companyId = this.authService.getCurrentUserCompany().id;

        if (this.filters.company) {
            query.company = this.filters.company;
        }
        if (this.filters.representationRight) {
            query.representationRight = this.filters.representationRight;
        }
        if (this.filters.largeClientManager && this.filters.largeClientManager.length > 0) {
            query.largeClientManagerUserIds = this.filters.largeClientManager;
        }
        if (this.quickFilter == 'inactive') {
            query.isDeleted = true;
        }
        if (this.quickFilter == 'all') {
            query.isAllUsers = true;
        }
        if (this.filters.personalCode) {
            query.personalCode = this.filters.personalCode;
        }
        if (this.filters.country) {
            query.country = this.filters.country;
        }
        if (this.filters.name) {
            query.name = this.filters.name;
        }
        if (this.filters.source !== null) {
            if (this.filters.source === 0) {
                query.isExternal = false;
            }
            if (this.filters.source === 1) {
                query.isExternal = true;
            }
        }
        if (this.filters.dateFrom) {
            query.dateFrom = this.filters.dateFrom;
        }
        if (this.filters.dateTo) {
            query.dateTo = this.filters.dateTo;
        }
        if (this.filters.ministry) {
            query.ministryRegNo = this.filters.ministry;
        }

        this.query = query;
        return query;
    }

    selectAllLargeClientManagers(event: any) {
        const isAllSelected = event.includes('All');
        if (isAllSelected) {
            this.filters.largeClientManager = this.largeClientManagerOptions.map(client => client.id);
            this.filterFormGroup.get('largeClientManager').patchValue(this.filters.largeClientManager);
        }
    }
}
