<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb breadcrumb-simple">
                    <li><a [routerLink]="['/list']">Kasutajad</a></li>
                    <li class="active">Kasutaja "{{ user?.forename }} {{ user?.surname }}" isikuandmed</li>
                </ol>
            </div>
        </div>
    </div>
</header>

<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Isikuandmed</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Isikukood:</div>
                        <div class="tbl-cell"><strong>{{ user?.personalCode }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Eesnimi:</div>
                        <div class="tbl-cell"><strong>{{ user?.forename }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Telefon:</div>
                        <div class="d-flex flex-wrap">
                            <div class="form-label"
                                 *ngFor="let phoneNumber of user?.phoneNumbers; let last = last;">
                                <strong>{{ phoneNumber }}
                                    <ng-container *ngIf="user?.phoneNumbers.length > 1  && !last">, {{ '&nbsp;' }}
                                    </ng-container>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Sugu:</div>
                        <div class="tbl-cell"><b>{{ user?.gender }}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Allikas:</div>
                        <div class="tbl-cell"><b>{{ user?.isExternal === false ? 'Sisene' : 'Väline' }}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">GDPR tingimustega nõustunud:</div>
                        <div class="tbl-cell"><b>{{ user?.gdprAccepted ? 'Jah' : 'Ei' }}</b></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Riik:</div>
                        <div class="tbl-cell"><strong>{{ user?.country?.name }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Perenimi:</div>
                        <div class="tbl-cell"><strong>{{ user?.surname }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Sünniaeg:</div>
                        <div class="tbl-cell">
                            <strong>{{ user?.birthday && !user?.birthday.includes(".") ? (user?.birthday | date:'dd.MM.yyyy') : user?.birthday }}</strong>
                        </div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">E-post:</div>
                        <div class="tbl-cell"><strong>{{ user?.email }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Lisainfo:</div>
                        <div class="tbl-cell"><strong>{{ user?.info }}</strong></div>
                    </div>
                    <div class="tbl-row" [hidden]="!fieldSapCostCenterEnabled">
                        <div class="tbl-cell tbl-cell-lbl w200">SAP kood:</div>
                        <div class="tbl-cell"><strong>{{ user?.sapCostCenter }}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Kasutaja grupp:</div>
                        <div class="tbl-cell" *ngIf="user?.adDptGroup">
                            <strong *ngFor="let groupCode of user?.adDptGroup.split(',')">
                                {{ groupCode | classifier:'AD_DPT_GROUP' }}<br>
                            </strong>
                        </div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Suurkliendihaldur:</div>
                        <div class="tbl-cell" *ngIf="user?.largeClientManagerUser">
                            <div class="tbl-cell"><strong>{{ user?.largeClientManagerUser.name }}</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right">
                    <a class="btn btn-inline btn-danger" (click)="delete()" *ngIf="user?.deleted === null"
                       [hidden]="!btnEditEnabled">Kustuta</a>
                    <a class="btn btn-inline btn-success" (click)="restore()" *ngIf="user?.deleted">Taasta</a>
                    <a [routerLink]="['/list']" class="btn btn-inline btn-secondary">Tagasi</a>
                    <a [routerLink]="['edit']" class="btn btn-inline btn-primary" [hidden]="!btnEditEnabled">Muuda</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="box-typical">
    <header class="box-typical-header">
        <div class="tbl-row">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-title">
                    <h3>Esindusõigused</h3>
                </div>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered"
                 *ngxPermissionsOnly="[
                 'representation-right.manage',
                 'user.manage.large-client-representation'
                  ]"
                 [hidden]="!btnEditEnabled">
                <button type="button" class="action-btn no-wrap" (click)="userAddRepresentationRight()">
                    <em class="font-icon font-icon-plus"></em>&nbsp;Lisa&nbsp;esindusõigus
                </button>
            </div>
        </div>
    </header>
    <table bs-table-control
           #bsTableElement
           [bs-table-options]="bsTableOptions"
           class="table-striped table-sm"
           data-pagination="true"
           data-id-field="id"
    ></table>
</section>

<susbstitution-table></susbstitution-table>

<section>
    <basis-of-assign [basisOfAssigns]="basisOfAssigns"></basis-of-assign>
</section>

